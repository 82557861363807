import { NextSeo } from 'next-seo';
import type { GetGlobalMetadataQuery } from 'server/repository/hygraph/generated/graphqlTypes';
import { getAdditionalLinksForNextSEO } from './helpers/getAdditionalLinksForNextSEO';
import { getImageDimensions } from './helpers/getImageDimensions';

type pageMetaDataType = {
  title?: string;
  openGraph: {
    title?: string;
    images: string[];
  };
  canonical?: string;
};
export type MetaDataType = GetGlobalMetadataQuery['globalMetaData'] & pageMetaDataType;

export const MetaDataNextSEO = ({
  metaData,
  locale,
}: {
  metaData: MetaDataType;
  locale: string;
}) => {
  return (
    <>
      <NextSeo
        canonical={metaData.canonical}
        title={
          `${(metaData?.title ?? metaData.siteName) || 'Dr. Oetker'}` ||
          `${metaData?.titleTemplate || ''}`
        }
        {...(metaData?.facebookAppId && {
          facebook: { appId: metaData?.facebookAppId },
        })}
        openGraph={{
          type: 'website',
          url: metaData.url,
          site_name: metaData?.siteName,
          description: metaData?.description ?? '',
          ...(!!metaData?.openGraph?.images?.length && {
            images: [
              ...metaData?.openGraph?.images?.map((image: string) => ({
                url: image,
                width: getImageDimensions(image)?.width,
                height: getImageDimensions(image)?.height,
              })),
            ],
          }),
          locale: locale ?? 'en_GB',
        }}
        additionalLinkTags={getAdditionalLinksForNextSEO(metaData)}
        description={metaData.description || ''}
      />
    </>
  );
};
